<template>
    <iPopup v-model="showPopup" position="bottom" ref="pop" :clickClose="true" class="itemPop">
        <div class="title">{{ title }}</div>
        <div v-if="tips" class="tips-title">
            <i-icon color="var(--opacityColor)" name="icon-gonggao"/>
            <span>{{ tips }}</span>
        </div>
        <div class="itemContent">
            <div class="item-box"
                 v-for="(item, index) in itemList"
                 v-show="item.show"
                 :key="index"
                 @click="addDemand(item)">
                <div class="item-icon" :style="{ background: item.iconBg || '' }">
                    <i-icon :size="item.size || 48" :color="item.iconColor" :name="item.icon"/>
                </div>
                <div class="item-name">{{ item.txt }}</div>
            </div>
        </div>
        <div class="cancelBtn" @click="showPopup = false">取消</div>
    </iPopup>
</template>
<script>
export default {
    name: "index",
    props: {
        title: {
            type: String,
            default: () => {
                return '选择供应商'
            }
        },
        tips: {
            type: String,
            default: () => {
                return ''
            }
        },
        itemList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            showPopup: false
        }
    },
    mounted() {
    },
    computed: {
        flag: {
            get: function () {
                var result = false
                this.itemList.forEach(item => {
                    if (item.itemType === "xiaomishu" && item.show) {
                        result = true
                    }
                })
                return result;
            }
        }
    },
    methods: {
        open() {
            this.showPopup = !this.showPopup
        },
        addDemand(item) {
            this.$emit('handlechannel', item.itemType, item);
        },
    }
};
</script>
<style lang="less" scoped>
.itemPop {
    .title {
        font-size: 0.16rem;
        text-align: center;
        height: 0.44rem;
        line-height: 0.44rem;
    }
    .tips-title {
        border-radius: 0.04rem;
        background: var(--themeColor);
        padding-left: 0.15rem;
        margin: 0 0.15rem 0.18rem 0.15rem;
        box-sizing: border-box;
        height: 0.36rem;
        line-height: 0.36rem;
        display: flex;
        align-items: center;
        svg {
            width: 0.18rem !important;
            height: 0.18rem !important;
            margin-right: .05rem;
        }
        span {
            // text-decoration: underline;
            font-size: 0.14rem;
            color: var(--opacityColor);
        }
    }
    .itemContent {
        padding: 0 0.15rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        .item-box {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 0.15rem;
            .item-icon {
                width: 0.72rem;
                height: 0.72rem;
                background: #f5f5f5;
                border-radius: 0.14rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .icon-box {
                    width: 0.48rem !important;
                    height: 0.48rem !important;
                }
            }
            .item-name {
                font-size: 0.12rem;
                margin-top: 0.1rem;
            }
        }
    }
    .cancelBtn {
        border-top: 0.05rem solid #f5f5f5;
        background: #ffffff;
        height: 0.44rem;
        line-height: 0.44rem;
        text-align: center;
        font-size: 0.14rem;
    }
}
</style>
