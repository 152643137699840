var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "iPopup",
    {
      ref: "pop",
      staticClass: "itemPop",
      attrs: { position: "bottom", clickClose: true },
      model: {
        value: _vm.showPopup,
        callback: function ($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup",
      },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.tips
        ? _c(
            "div",
            { staticClass: "tips-title" },
            [
              _c("i-icon", {
                attrs: { color: "var(--opacityColor)", name: "icon-gonggao" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.tips))]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "itemContent" },
        _vm._l(_vm.itemList, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.show,
                  expression: "item.show",
                },
              ],
              key: index,
              staticClass: "item-box",
              on: {
                click: function ($event) {
                  return _vm.addDemand(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-icon",
                  style: { background: item.iconBg || "" },
                },
                [
                  _c("i-icon", {
                    attrs: {
                      size: item.size || 48,
                      color: item.iconColor,
                      name: item.icon,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "item-name" }, [
                _vm._v(_vm._s(item.txt)),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "cancelBtn",
          on: {
            click: function ($event) {
              _vm.showPopup = false
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }